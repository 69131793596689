import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, filter} from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

const recruitRoles = 
['transcoder','transcriber', 'translator', 'timer'];

@Component({
  selector: 'cns-join-us-roles-textfield',
  templateUrl: './join-us-roles-textfield.component.html',
  styleUrls: ['./join-us-roles-textfield.component.scss']
})
export class JoinUsRolesTextfieldComponent implements OnInit {

  model: any;

  constructor() { }

  ngOnInit() {
  }
  
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? recruitRoles
        : recruitRoles.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

}

