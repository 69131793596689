import { Component, OnInit, Input, ViewEncapsulation, Renderer2, RendererFactory2, ViewChild } from '@angular/core';

const partnersArray = [ {
    name: 'AbotHameiriBW',
    path: '../../assets/logos/AbotHameiriBW.jpg'
  }, {
    name: 'BlackSheep',
    path: '../../assets/logos/BlackSheep.jpg'
  }, {
    name: 'DocAviv',
    path: '../../assets/logos/DocAviv.png'
  }, {
    name: 'Endemol_Logo',
    path: '../../assets/logos/Endemol_Logo.png'
  }, {
    name: 'i24',
    path: '../../assets/logos/i24.png'
  }, {
    name: 'JulyAugust',
    path: '../../assets/logos/JulyAugust.png'
  }, {
    name: 'Kehset12',
  path: '../../assets/logos/Kehset12.png'
  }, {
    name: 'Reshet13',
  path: '../../assets/logos/Reshet13.png'
  }, {
    name: 'sumayoko',
  path: '../../assets/logos/sumayoko.png'
  }, {
    name: 'Tedy',
  path: '../../assets/logos/Tedy.png'
  }, {
    name: 'UCM_vector',
  path: '../../assets/logos/UCM_vector.png'
  }, {
    name: 'UNitedKingFilms',
    path: '../../assets/logos/UNitedKingFilms.png'
  }
]

@Component({
  selector: 'cns-partners-footer',
  templateUrl: './partners-footer.component.html',
  styleUrls: ['./partners-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartnersFooterComponent implements OnInit {

  @Input("numberOfPartners")
  public numberOfPartners = 11;
  @ViewChild('partnersFooter')
  public partnersFooter;

  public transformPixels = 0;
  public selectedElem = 0;
  public partnersArray = partnersArray;
  private renderer: Renderer2;

  constructor(private rendererFactory2 : RendererFactory2) {
    this.renderer = rendererFactory2.createRenderer(null, null);
   }

  ngOnInit() {
  }

  public isEndOfCarousel($event)
  {
    if ($event.index >= partnersArray.length)
    {
      return true;
    }
    return false;
  }
  public changeTransform(button = 'next') {
    if(button == 'next') {
      if(this.selectedElem < partnersArray.length )
      {  

        console.log(this.partnersFooter.nativeElement.children[this.selectedElem]);
        this.transformPixels += this.partnersFooter.nativeElement.children[this.selectedElem++].offsetWidth + 60;
        this.renderer.setStyle(this.partnersFooter.nativeElement, 
          'transform', 'translateX(-' + (this.transformPixels) + 'px)');
          console.log('button next!');
          console.log('current transformPixels: ', this.transformPixels);
      } 
      else {
        console.log("reached carousel end! this.selectedElem is ", this.selectedElem); 
      }
     
    }
    else if(button == 'prev')
    {
      if(this.selectedElem > 0)
      {
      
      this.transformPixels -= this.partnersFooter.nativeElement.children[this.selectedElem--].offsetWidth + 60;
      this.renderer.setStyle(this.partnersFooter.nativeElement, 
        'transform', 'translateX(-' + (this.transformPixels) + 'px)');
        console.log('button next!');
        console.log('current transformPixels: ', this.transformPixels);
        }
        
      
      }

  }

}
