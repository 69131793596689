export class JoinUsModel {
    public firstName: string;
    public lastName: string;
    public email: string;
    public sourceLanguages: string;
    public targetLanguages: string;
    public skills: string;
    public country: string;
    public extra: string;
}


