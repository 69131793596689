import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';


@Component({
  selector: 'cns-navbar',
  templateUrl: './cns-navbar.component.html',
  styleUrls: ['./cns-navbar.component.scss']
})

export class CnsNavbarComponent implements OnInit {
  
  @ViewChild("navbarRoot")
  public navbarRoot : ElementRef;
  @ViewChild("navbarSupportedContent")
  public navbarSupportedContent: ElementRef;
  public selectedLink = 'home';
  public navbarToggled : boolean;
  private listenerTransitionEnd : Function;
  constructor(private renderer: Renderer2) {
    this.navbarToggled = false;
  }

  ngOnInit() {
    this.onScrollEvent();
  }

  @HostListener('window:scroll', ['$event']) 
    onScrollEvent(){
    let verticalOffset = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;
    if(verticalOffset > 50)
    {
      this.renderer.addClass(
        this.navbarRoot.nativeElement, 'scrolled')
    }
    else {
      this.renderer.removeClass(
        this.navbarRoot.nativeElement, 'scrolled');
      }
  } 

  public toggleNavContent() {
    if(this.listenerTransitionEnd)
    {
      this.listenerTransitionEnd();
    }

    if(!this.navbarToggled){
        this.navbarToggled = !this.navbarToggled;   
        
        this.renderer.setStyle(
          this.navbarSupportedContent.nativeElement,
          'visibility',
          'hidden'
        );
        this.renderer.setStyle(
          this.navbarSupportedContent.nativeElement,
          'position',
          'absolute'
        );        
        setTimeout(()=> {
        let fullHeight = this.getNavContentHeight();
        
        console.log("current fullHeight: ", fullHeight);
        console.log("Starting 0 height placement!")
        this.renderer.setStyle(
          this.navbarSupportedContent.nativeElement,
          'height',
          0
        );
        this.renderer.setStyle(
          this.navbarSupportedContent.nativeElement,
          'position',
          'initial'
        ); 
        this.renderer.setStyle(
          this.navbarSupportedContent.nativeElement,
          'visibility',
          'visible'
        );

        setTimeout (()=> {
          console.log("toggling Navbar...");
          console.log('navbarToggle status: ', this.navbarToggled);
          console.log("Starting "+ fullHeight +"px height placement!");
          this.renderer.setStyle(
            this.navbarSupportedContent.nativeElement,
            'height',
            fullHeight+'px'
            );
            this.listenerTransitionEnd = this.renderer.listen(this.navbarSupportedContent.nativeElement, 'transitionend', (event) => {
              this.renderer.removeStyle(
                this.navbarSupportedContent.nativeElement,
                'height');
            });
            
          }, 5 
        );
      }, 1);
    }
    else {
      this.navbarToggled = !this.navbarToggled;
      setTimeout(()=> {
        this.renderer.removeStyle(
          this.navbarSupportedContent.nativeElement,
          'height');
      }, 5);
    }
  }
  public getNavContentHeight() {
    console.log("in function fullheight: ", this.navbarSupportedContent.nativeElement.offsetHeight);
    return this.navbarSupportedContent.nativeElement.offsetHeight;
    
  }

}
