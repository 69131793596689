import { HttpClient } from '@angular/common/http';
import { JoinUsModel } from './join-us-model';
import { Component, ViewChild, Renderer2, AfterViewInit, HostListener, ViewEncapsulation, Inject, Directive, OnInit } from '@angular/core';
import { ElementRef, ChangeDetectorRef  } from '@angular/core';
import stickybits from 'stickybits';
import svg4everybody from 'svg4everybody';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { fromEvent, interval, } from 'rxjs';
import { debounceTime, map, distinct, skip} from 'rxjs/operators';
import * as AOS from 'aos';
import { DOCUMENT } from '@angular/common';

const breakpointConfig = {
  xs: 'screen and (max-width: 575.98px)',
  sm: 'screen and (min-width: 576px)',
  md: 'screen and (min-width: 768px)',
  lg: 'screen and (min-width: 992px)',
  xl: 'screen and  (min-width: 1200px)'
};

// const bubbleTitles = [
//   { Title1: 'What we do ONE',
//     Title2: ''},
//   { Title1: 'What we do TWO',
//   Title2: 'Woah'},
//   { Title1: 'What we do THREE',
//   Title2: 'Woah'}
// ]

enum JoinUsRequstStatus {
  Process = 1,
  Success = 2,
  Failed = 3
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit, OnInit {

  @ViewChild('stickyParent')
  public stickyParent: ElementRef;

  @ViewChild('sectionsParent')
  public sectionsParent: ElementRef;

  public stickybitInstance: any;
  public partnersArray = Array;

  public currentSection;
  public scrollingEventListener;
  public onWheelEventListener;
  public currentSnappedPane;
  private isScrollLocked: boolean;
  private scrollingEventListeners = [];

  private windowMediaQueries = window.matchMedia(breakpointConfig.lg);

  public joinUsModel = new JoinUsModel();
  public joinUsRequestStatus: JoinUsRequstStatus;
  public JoinUsRequestStatus = JoinUsRequstStatus;
  public displayContactUs = false;

  constructor(private renderer: Renderer2,
    private elRef: ElementRef,
    private cdref: ChangeDetectorRef,
    private _scrollToService: ScrollToService,
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: any) {


  }

  ngOnInit() {
    AOS.init({
      anchorPlacement: 'bottom-bottom',
      delay: 400
    });

  }

  ngAfterViewInit() {
    // this.defineStickySideBarsZindex();
    // this.stickyApply();
    // this.scrollDist();
    svg4everybody();
    this.cdref.detectChanges();
    this.currentSection = 1;
    fromEvent(window, 'resize').pipe(debounceTime(400))
      .subscribe((event) => {
        this.snapToSection(event);
      });

    // Subscribe event to detect Scroll
    this.scrollingEventListener = fromEvent(window, 'scroll')
      .pipe(skip(2),
        map(() => window.scrollY),
        debounceTime(10),
        distinct());
    this.scrollingEventListener.subscribe((event) => {
      this.snapToSection(event);
    });

    //   this.onWheelEventListener = fromEvent(window, 'wheel')
    //   .pipe(
    //     debounceTime(10),
    //     distinct());
    //   console.log("event");

    //   if(!this.isScrollLocked){
    //   this.onWheelEventListener.subscribe((event) => {
    //     // scroll to element based on location
    //     console.log(event);
    //     if(this.currentSection < this.sectionsParent.nativeElement.children.length){
    //       console.log(this.sectionsParent.nativeElement.children);
    //     this._scrollToService.scrollTo(
    //       {target: this.sectionsParent.nativeElement.children[this.currentSection] });

    //       if(event.deltaY >= 0){
    //       this.currentSection += 1
    //       }
    //       else{
    //         this.currentSection -= 1
    //       }
    //     }
    //       // console.log(event.deltaY >= 0 ?
    //     //              "wheel goes down!": "wheel goes up!" );
    //     // this.snapToSection(event);
    //   })

    // }
  }

  public async sendJoinUsRequest() {
    if (this.joinUsRequestStatus) {
      return;
    }
    this.joinUsRequestStatus = JoinUsRequstStatus.Process;
    try {
      await this.httpClient.post('/controllers/joinus/send', this.joinUsModel).toPromise();
      this.joinUsModel = new JoinUsModel();
      this.joinUsRequestStatus = JoinUsRequstStatus.Success;
    } catch {
      this.joinUsRequestStatus = JoinUsRequstStatus.Failed;
    }
  }

  public snapToSection(event) {
    for (let i = this.sectionsParent.nativeElement.children.length - 1; i >= 0; i--) {
      const paneYPixelsInView = (window.innerHeight - this.sectionsParent.nativeElement.children[i]
        .getBoundingClientRect().top);
      const paneYPixelsInViewBottom = (window.innerHeight - this.sectionsParent.nativeElement.children[i]
        .getBoundingClientRect().bottom);
      // console.log( {
      //             curPane : this.sectionsParent.nativeElement.children[i].id,
      //             paneYPixelsInView: paneYPixelsInView,
      //             paneYPixelsInViewBottom: paneYPixelsInViewBottom,
      //             clientHeight: this.sectionsParent.nativeElement.children[i].clientHeight
      //             } );

      if ((paneYPixelsInView > 0 && event.deltaY >= 0) ||
        (paneYPixelsInViewBottom > 0 && event.deltaY < 0 &&
          paneYPixelsInViewBottom <=
          this.sectionsParent.nativeElement.children[i].clientHeight)) {
        // console.log("pane being validated correct was: ", i)
        // console.log("currentSnappedPane: ", this.currentSnappedPane);
        if (this.currentSnappedPane !== i) {
          // console.log('this should be element: ',
          // this.sectionsParent.nativeElement.children[i]);
          this.currentSnappedPane = i;
          this.disableScroll();
          this.isScrollLocked = true;
          this._scrollToService.scrollTo({
            target: this.sectionsParent.nativeElement.children[i].id
          });
          // if(event.deltaY < 0)
          //   break;

          setTimeout(() => {
            if (this.isScrollLocked) {
              this.enableScroll();
            }
          }, 2000);
        }

        if (event.deltaY >= 0) {
          break;
        }
      }
    }
  }

  private disableScroll() {
    console.log('scroll disabbled!');

    // this.renderer.listen(this.sectionsParent.nativeElement, 'click', (event) => {});
    this.scrollingEventListeners.push(this.renderer.listen(window, 'wheel', this.preventDefault));
    this.scrollingEventListeners.push(this.renderer.listen(window, 'mousewheel', this.preventDefault));
    this.scrollingEventListeners.push(this.renderer.listen(window, 'touchmove', this.preventDefault));
    this.scrollingEventListeners.push(this.renderer.listen(window, 'keydown', this.preventDefaultForScrollKeys));
    // window.onwheel = this.preventDefault; // modern standard
    // window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
    // window.ontouchmove  = this.preventDefault; // mobile
    // document.onkeydown  = this.preventDefaultForScrollKeys;

  }

  private enableScroll() {
    console.log('scroll enabled!');

    // this.renderer.listen(this.sectionsParent.nativeElement, 'click', (event) => {});
    // window.onwheel = null; // modern standard
    // window.onmousewheel = document.onmousewheel = null; // older browsers, IE
    // window.ontouchmove  = null; // mobile
    // document.onkeydown  = null;
    this.scrollingEventListeners.forEach(listener => {
      listener();
    });
    this.scrollingEventListeners = [];
  }

  private preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  }

  private preventDefaultForScrollKeys(e) {
    const keys = {
      37: 1,
      38: 1,
      39: 1,
      40: 1
    };
    if (keys[e.keyCode]) {
      this.preventDefault(e);
      return false;
    }
  }

  // private defineStickySideBarsZindex() {
  //   for(let i = 0;
  //     i < this.stickyParent.nativeElement.children.length;
  //     i++) {
  //     this.renderer.setStyle(
  //       this.stickyParent.nativeElement.children[i],
  //     'z-index', (1000 - i) );
  //     }
  // }

  private stickyApply(apply: boolean = true, instance: number = 0) {
    if (this.checkIfMobileMode() || !apply) {
      if (this.stickybitInstance) {
        this.stickybitInstance.cleanup();
        this.renderer.removeStyle(
          this.stickyParent.nativeElement.children[instance], 'position');
        this.renderer.removeStyle(
          this.stickyParent.nativeElement.children[instance], 'top');
        this.stickybitInstance = null;
      }
    } else {
      this.stickybitInstance = stickybits(this.stickyParent.nativeElement.children[instance]);
    }
  }

  private checkIfMobileMode() {
    if (this.windowMediaQueries.matches) {
      // If it is BIGGER than mobile size
      return false;
    } else {
      return true;
    }
  }


  // public scrollDist() {
  //   if(this.checkIfMobileMode()) {
  //     return;
  //   }
  //   else {
  //     for (let i = this.sectionsParent.nativeElement.children.length - 1;
  //       i >= 0 ;
  //       i--)
  //       {
  //         if((window.innerHeight - this.sectionsParent.nativeElement.children[i]
  //             .getBoundingClientRect().top) >
  //             // (this.sectionsParent.nativeElement.children[i].clientHeight / 2) )
  //             300 )
  //         {
  //               this.currentBubbleTitle =
  //               this.bubbleTitlesService.getTitlesByIndex(i);
  //           break;

  //         }
  //       }
  //   }
  // }

  loginClick(): void {
    this.document.location.href = this.document.location.origin + '/login.aspx#!/';
  }




}
