import { AfterContentInit } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[cnsScrollToElement]'
})
export class ScrollToElementDirective implements AfterContentInit {

  constructor(private el: ElementRef) {}
  
  public ngAfterContentInit() {
    this.el.nativeElement.scrollIntoView();
  }

}
