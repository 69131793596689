import { Component, OnInit, Input, OnChanges, SimpleChange,
   SimpleChanges, ChangeDetectorRef , ChangeDetectionStrategy } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';


@Component({
  selector: 'cns-bubble-titles',
  templateUrl: './bubble-titles.component.html',
  styleUrls: ['./bubble-titles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ trigger('TitleFadeOut', 
  [
    state('*', style({
      opacity: 0
    })),
    transition('* => *', animate('0.5s ease-out',keyframes([
      style({ opacity: 1, offset: 0}),
      style({ opacity: 0, offset: 1})
    ])))
  ])
  ,
    trigger('TitleFadeIn', [
    state('*', style({
      opacity: 1
    })),
    transition('* => *', animate('0.5s ease-in', keyframes([
      style({ opacity: 0, offset: 0}),
      style({ opacity: 0, offset: 0.5}),
      style({ opacity: 1, offset: 1})
    ])))
    ])]
})

export class BubbleTitlesComponent implements OnInit {

  @Input('bubbleTitle1')
  public bubbleTitle1: string;
  public currBubbleTitle1: string;
  
  @Input('bubbleTitle2')
  public bubbleTitle2: string;
  currBubbleTitle2: string;

  constructor(private cdref: ChangeDetectorRef) { }

  ngOnInit() {
    this.currBubbleTitle1 = this.bubbleTitle1;
    this.currBubbleTitle2 = this.bubbleTitle2;
  }

  ngOnChanges(changes: SimpleChanges ): void {
    // console.log(changes); 
  }
  
  FadeoutComplete() {
    this.currBubbleTitle1 = this.bubbleTitle1;
    this.currBubbleTitle2 = this.bubbleTitle2;
    // console.log(this.currBubbleTitle1);
    this.cdref.detectChanges();
  }

}
