import { DeviceDetectorService } from 'ngx-device-detector';

import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cns-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('slideAnim', [
    state('inactive', style({
      opacity: 0
    })),
    state('active', style({
      opacity: 1
    })),
    transition('active => *', animate('0s ease-out', keyframes([
      style({
        opacity: 1,
        offset: 0
      }),
      style({
        opacity: 0,
        offset: 1
      })
    ])))
  ])]
})
export class CarouselComponent implements OnInit {
  images: Array < string > ;
  imagesStates = [];
  vari = "true";

  constructor(config: NgbCarouselConfig,
    private cdref: ChangeDetectorRef,
    private readonly deviceService: DeviceDetectorService) {
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    // config.pauseOnHover = false;
  }

  public ngOnInit(): void {
    this.loadCarouselImages();
    this.setImagesState();
  }

  private loadCarouselImages() {
    this.images = [
      '../../assets/page6-cinema-v4-{0}.jpg',
      '../../assets/page6-docs-v4-{0}.jpg',
      '../../assets/page6-television-v4-{0}.jpg'
    ];
    const isMobile = this.deviceService.isMobile();
    this.images = this.images.map(image => isMobile ? image.format('mobile') : image.format('desktop'));
  }

  private setImagesState() {
    for (let i = 0; i < this.images.length; i++) {
      this.imagesStates.push('inactive');
    }
    this.imagesStates[0] = 'active';
  }

  slideChanged(event) {
    let prevSlide = event.prev.split('ngb-slide-')[1];
    let curSlide = event.current.split('ngb-slide-')[1];
    this.imagesStates[prevSlide] = 'inactive';
    this.imagesStates[curSlide] = 'active';
    this.cdref.detectChanges();
  }
}
