import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[redirect]'
})
export class RedirectDirective {

  constructor() { }

  @Input()
  public redirect: string;

  @HostListener('click')
  public redirectToUrl() {
    window.location.href = this.redirect;
  }
}
