import { Angular2FontawesomeModule } from 'angular2-fontawesome/angular2-fontawesome';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { StickyModule } from 'ng2-sticky-kit';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import * as stickybits from 'stickybits';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { AppComponent } from './app.component';
import { BubbleTitlesComponent } from './bubble-titles/bubble-titles.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CnsNavbarComponent } from './cns-navbar/cns-navbar.component';
import {
    JoinUsRolesTextfieldComponent
} from './join-us-roles-textfield/join-us-roles-textfield.component';
import { PartnersFooterComponent } from './partners-footer/partners-footer.component';
import { RedirectDirective } from './redirect.directive';
import { ScrollToElementDirective } from './scroll-to-element.directive';
import { StickyBitsDirective } from './sticky-bits.directive';

@NgModule({
  declarations: [
    AppComponent,
    CnsNavbarComponent,
    StickyBitsDirective,
    BubbleTitlesComponent,
    CarouselComponent,
    JoinUsRolesTextfieldComponent,
    PartnersFooterComponent,
    RedirectDirective,
    ScrollToElementDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    StickyModule,
    InfiniteScrollModule,
    HttpClientModule,
    FormsModule,
    ScrollToModule.forRoot(),
    Ng2CarouselamosModule,
    Angular2FontawesomeModule,
    DeviceDetectorModule.forRoot()
    ],
  providers: [ HttpClientModule, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
